import React, {Component} from "react";
import {Badge} from 'primereact/badge';
import {withTranslation} from "react-i18next";
import {bindMethods, getIconCode} from "../utilities";
import "./ColumnTextView.less";
import {classNames, getVisibleTextData} from "../utilities/components";

class ColumnTextView extends Component {

  constructor(props) {
    super(props);

    // Bind events
    bindMethods(this, ["onClick"]);
  }

  onClick() {
    const {actions, addActionsTop, address} = this.props;
    if (actions.length > 0) {
      addActionsTop(actions.map(action => ({...action, address: {...address}})));
    }
  }

  render() {
    const {t, data, align} = this.props;
    const {style, title, label, value} = data;
    const icon = data.icon || this.props.icon || undefined;
    const unit = data.unit || this.props.unit || null;
    const classes = classNames("text-view", style);
    return <div className={classes} title={t(title || label)} onClick={this.onClick}>
      <span className={"text-view-icon"}>{getIconCode(icon, "fa-fw")}</span>
      <span className={"text-view-text"} style={{textAlign: align}}>{getVisibleTextData(label || value, t)}</span>
      {unit && <Badge value={t(unit)} severity="secondary" style={{justifyContent: "center"}}/>}
    </div>;
  }
}

export default withTranslation()(ColumnTextView);
