import {addLocale} from "primereact/api";
import en from "./highcharts/highcharts-en.json";
import es from "./highcharts/highcharts-es.json";
import fr from "./highcharts/highcharts-fr.json";

/**
 * Init highcharts locales
 */
export function initHighChartLocales() {
  [
    {lang: "en", locale: en},
    {lang: "es", locale: es},
    {lang: "fr", locale: fr}
  ].forEach(l => addLocale(l.lang, l.locale));
}
